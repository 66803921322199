import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import RegistrationForm from '../components/registration/openHausForm'
import SEO from '../components/seo'
import { theme, FormTitle } from '../util/style'

const RegistrationBanyo = () => {
  return (
    <>
      <SEO title="Open Haus Registration" />
      <Flex width={[1]}>
        <Box width={1} px={2}>
          <FormBox>
            <FormTitle>Welcome to Open Haus</FormTitle>
            <RegistrationForm />
          </FormBox>
        </Box>
      </Flex>
    </>
  )
}

export default RegistrationBanyo

const FormBox = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 800px) {
    background: ${theme.colors.grey4};
  }
`
